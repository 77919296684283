<template>
	<div>
		<FilterChips
			v-show="!showFilter"
			:filters="filters"
			:showFilter="showFilter"
			@resetFilter="resetFilter"
		/>

		<div class="filter-content" :class="{'show': showFilter}">
      <v-card class="pa-5 base-card" :class="{'show': showFilter}">
        <v-row>
          <v-col cols="4">
            <DatePicker
              :date="filters.fromDate"
              label="Дата с"
              :minDate="minDate"
              @setDate="filters.fromDate = $event; input()"
            />
          </v-col>

          <v-col cols="4">
            <DatePicker
              :date="filters.toDate"
              label="Дата по"
              :minDate="minDate"
              @setDate="filters.toDate = $event; input()"
            />
          </v-col>
        </v-row>
      </v-card>
		</div>
	</div>
</template>

<script>
import { formatDateToISO } from '@/scripts'
import FilterChips from '@/components/employess/load/FilterChips'
import DatePicker from '@/components/ui/DatePicker'

export default {
	name: 'ListenersFilter',

	components: {
    FilterChips,
    DatePicker
	},

	props: {
		showFilter: Boolean
	},

  data: () => ({
    minDate: formatDateToISO(new Date(2016, 0, 1)),

    filters: {
      fromDate: formatDateToISO(new Date(2016, 0, 1)),
      toDate: formatDateToISO(new Date(new Date().getFullYear(), 11, 31))
    }
	}),

	methods: {
		input() {
      const body = {
        fromDate: this.filters.fromDate,
        toDate: this.filters.toDate
      }

			this.$emit('setFilters', body)
		},

		resetFilter(filters) {
			this.filters = { ...filters }
			this.input()
		}
	}
}
</script>