<template>
  <div>
    <div class="text-center" v-if="!dataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-show="dataLoaded">
      <PageTitle title="Нагрузка преподавателей кафедры" />

      <div ref="infoBlocks">
        <v-row class="my-0">
          <v-col cols="12" class="py-0">
            <h4 class="department">{{ departmentName }}</h4>
          </v-col>
        </v-row>
      </div>

      <LoadButtons
        :showFilter="showFilter"
        :filters="filters"
        @setShowFilter="setShowFilter"
      />

      <Filters
        ref="loadFilters"
        :showFilter="showFilter"
        @setFilters="setFilters"
      />

      <LoadTable
        :tableLoading="tableLoading"
        :employeesLoad="employeesLoad"
        :chipsHeight="chipsHeight"
        :filtersHeight="filtersHeight"
        :infoBlocksHeight="infoBlocksHeight"
      />
    </div>
  </div>
</template>

<script>
import { workloadApi } from '@/api'
import { mapGetters } from 'vuex'
import { formatDateToISO } from '@/scripts'
import _debounce from 'lodash/debounce'
import _cloneDeep from 'lodash/cloneDeep'
import PageTitle from '@/components/ui/Title'
import LoadTable from '@/components/employess/load/Table'
import LoadButtons from '@/components/employess/load/Buttons'
import Filters from '@/components/employess/load/Filters'

export default {
  name: 'EmployeesLoad',

  metaInfo: {
    title: 'Нагрузка преподавателей кафедры'
  },

  components: {
    PageTitle,
    LoadTable,
    LoadButtons,
    Filters
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.dataLoaded = true
    this.setFilterElementsHeight()
    this.setDefaultFilters()
    await this.getEmployeesLoad()
  },

  mounted() {
    this.hideHtmlOverflow()
  },

  destroyed() {
    this.showHtmlOverflow()
  },

  data: () => ({
    dataLoaded: false,
    tableLoading: false,
    showFilter: false,
    chipsHeight: 0,
    filtersHeight: 0,
    infoBlocksHeight: 0,

    employeesLoad: [],
    filters: {}
  }),

  computed: {
    ...mapGetters('department', ['departmentId', 'departmentName'])
  },

  methods: {
    async getEmployeesLoad() {
      try {
        this.tableLoading = true

        const body = {
          ...this.filters,
          departmentId: this.departmentId
        }

        this.employeesLoad = await workloadApi.getEmployeesLoad(body)
      } catch (e) {
        console.log(e.status)
      } finally {
        this.tableLoading = false
      }
    },

    setDefaultFilters() {
      this.filters = {
        fromDate: formatDateToISO(new Date(2016, 0, 1)),
        toDate: formatDateToISO(new Date(new Date().getFullYear(), 11, 31))
      }
    },

    setShowFilter() {
      this.showFilter = !this.showFilter
      this.setFilterElementsHeight()
    },

    setFilterElementsHeight() {
      this.$nextTick(() => {
        const chips = this.$refs.loadFilters.$children[0]
        const filters = this.$refs.loadFilters.$children[1]

        if (chips) this.chipsHeight = chips.$el.offsetHeight
        if (filters) this.filtersHeight = filters.$el.offsetHeight
      })
    },

    setFilters: _debounce(async function(filters) {
      this.filters = _cloneDeep(filters)

      await this.getEmployeesLoad()
      this.setFilterElementsHeight()
    }, 500),

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>