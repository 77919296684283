<template>
  <v-row class="my-0">
    <v-col cols="12" class="py-0">
      <v-card class="table-card" :class="chipsHeight ? 'mt-3' : 'mt-4'">
        <v-card-title>
          <span class="accent--text">Нагрузка</span>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="tableSearch"
            class="mt-0 pt-0 table-search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          ref="employeesTable"
          class="base-table"
          loading-text="Загрузка..."
          fixed-header
          hide-default-footer
          :loading="tableLoading"
          :headers="headers"
          :items="employeesLoad"
          :search="tableSearch"
          :items-per-page="-1"
        >
          <template #header.time="{ header }">
            <span v-html="header.text"></span>
          </template>

          <template slot="no-data">
            <div class="py-3">Нет нагрузки преподавателей на данной кафедре</div>
          </template>

          <template slot="no-results">
            <div class="py-3">Ничего не найдено</div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmployeesLoadTable',

  props: {
    tableLoading: Boolean,
    chipsHeight: Number,
    filtersHeight: Number,
    employeesLoad: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    tableSearch: null,
    headers: [
      { text: 'ФИО и должность', value: 'shortName', sortable: false, align: 'left' },
      { text: 'Лекции', value: 'hoursLectures', sortable: true, align: 'center' },
      { text: 'Практика', value: 'hoursPractice', sortable: true, align: 'center' },
      { text: 'Семинары', value: 'hoursSeminars', sortable: true, align: 'center' },
      { text: 'Всего часов', value: 'hoursTotal', sortable: true, align: 'center' }
    ]
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId'])
  },

  methods: {
    setTableHeight() {
      const chipsHeight = this.chipsHeight ? this.chipsHeight + 12 : 16
      const filtersHeight = this.filtersHeight ? this.filtersHeight + 16 : 0
      // суммарная высота элементов на странице, кроме таблицы
      const otherElements = 339 + chipsHeight + filtersHeight + this.techMessageHeight

      if (this.$refs?.employeesTable) {
        this.$refs.employeesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    }
  },

  watch: {
    chipsHeight() {
      this.setTableHeight()
    },

    filtersHeight() {
      this.setTableHeight()
    }
  }
}
</script>