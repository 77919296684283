<template>
  <v-menu
    ref="menu"
    v-model="menu"
    content-class="calendar-menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-on="on"
        v-model="dateLocalFormatted"
        class="mt-0"
        :label="label"
        append-icon="mdi-calendar-outline"
        v-bind="attrs"
        v-mask="'##.##.####'"
        hide-details
        @change="setDate"
      />
    </template>

    <v-date-picker
      v-model="dateLocal"
      class="base-calendar"
      no-title
      locale="ru"
      color="primary"
      :min="minDate"
      :max="maxDate"
      :first-day-of-week="1"
      :picker-date.sync="startMonthLocal"
      :allowed-dates="allowedDates"
      @input="setDate"
    />
  </v-menu>
</template>

<script>
import { formatDateReverseFromISO, formatDateReverseToISO } from '@/scripts'

export default {
  name: 'DatePicker',

  props: {
    date: String,
    minDate: String,
    maxDate: String,
    startMonth: String,
    label: String,
    allowedDates: {
      type: Function,
      default: null
    }
  },

  data: () => ({
    menu: false,
    dateLocal: null,
    dateLocalFormatted: null,
    startMonthLocal: null
  }),

  methods: {
    formatDate (date) {
      if (!date) return null
      return formatDateReverseFromISO(date)
    },

    parseDate (date) {
      if (!date) return null
      return formatDateReverseToISO(date)
    },

    isISO(date) {
      return /(\d{4})-(\d{2})-(\d{2})/.test(date)
    },

    setDate(date) {
      const formatDate = this.isISO(date) ? date : this.parseDate(date)
      this.$emit('setDate', formatDate)

      this.menu = false
    }
  },

  watch: {
    date: {
      immediate: true,
      handler(val) {
        this.dateLocal = val
        this.dateLocalFormatted = this.formatDate(val)
      }
    },

    dateLocal (val) {
      this.dateLocalFormatted = this.formatDate(val)
    },

    startMonth: {
      immediate: true,
      handler(val) {
        this.startMonthLocal = val
      }
    }
  }
}
</script>

<style scoped>
.calendar-menu {
  border-radius: 10px !important;
}

.base-calendar >>> .v-date-picker-header {
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.base-calendar >>> .v-date-picker-header .theme--light.v-btn.v-btn--disabled .v-icon {
  color: var(--disabled-color) !important;
}

.base-calendar >>> .v-date-picker-header .theme--light.v-btn.v-btn--disabled {
  background-color: #fff !important;
}

.base-calendar >>> .v-date-picker-table {
  height: 250px;
}

.base-calendar >>> .v-date-picker-table th {
  color: var(--accent-color) !important;
}

.base-calendar >>> .v-btn {
  color: var(--accent-color) !important;
}

.base-calendar >>> .theme--light.v-btn.v-btn--disabled {
  color: var(--disabled-color) !important
}

.base-calendar >>> .v-date-picker-table .v-btn.v-btn--active {
  color: #fff !important;
}

.base-calendar >>> .v-date-picker-table__current {
  border-color: var(--primary-color) !important;
}

.base-calendar >>> .v-date-picker-header__value button {
  color: var(--accent-color) !important;
}
</style>